import { Box } from "@mui/material";
import { NavigationAndFooterLayout } from "../layouts";
import { NextPageWithLayout, RoleBooklets } from "@cm-websites/common";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import i18nConfig from "../../next-i18next.config";
import type { GetStaticProps } from "next";

export const VorbereitungPage: NextPageWithLayout = () => (
  <Box sx={{ pt: { xs: 12, sm: 14, lg: 16 }, pb: { xs: 8, sm: 10, lg: 14, xl: 16 } }}>
    <RoleBooklets />
  </Box>
);

VorbereitungPage.getLayout = (page) => <NavigationAndFooterLayout>{page}</NavigationAndFooterLayout>;

export default VorbereitungPage;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(locale ? await serverSideTranslations(locale, ["c-support", "c-common"], i18nConfig) : {}),
    },
  };
};
